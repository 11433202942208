import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';

const RangeDatePicker = ({setDate}) => {
    const datePickerRef = useRef(null);

    useEffect(() => {
        // Initialize the daterangepicker
        $(datePickerRef.current).daterangepicker(
            {
                autoApply: true, // Automatically apply the selected date
                opens: 'right', // Position of the calendar
                locale: {
                    format: 'YYYY-MM-DD', // Date format
                },
            },
            (start, end) => {
                setDate(`${start.format('YYYY-MM-DD')} to ${end.format('YYYY-MM-DD')}`)
            }
        );

        // Cleanup
        return () => {
            $(datePickerRef.current).data('daterangepicker').remove();
        };
    }, []);

    return <input ref={datePickerRef} type="text" placeholder="Select date range" />;
};

export default RangeDatePicker;
