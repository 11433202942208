const fr = {
    welcomeDescription: 'Salut!',
    exploreHeader: 'Explorer',    
    buttonClicked: 'Cliquez',
    aboutMe: 'DADUPA',
    project: 'Tous les projets',
    listproject: 'Liste des projets',
    'signup.message1': 'Bienvenue sur DADUPA,',
    'signup.message2': 'Le réseau social des entrepreneurs',
    email: 'E-mail',
    'signup.form.email': 'Entrez votre adresse e-mail',
    password: 'Mot de passe',    
    next: 'Suivant',
    previous: 'Précédent',
    review: 'Vérification',
    targetAreas: 'Head (HQ)',
    funding: 'Financé',
    'funding.startup': 'Financé',
    'title': 'Titre',
    sector: 'Secteur d\'activité',
    status: 'Statut',
    'signup.message3': 'En m\'inscrivant, j\'accepte les Conditions d\'utilisation',
    'signup.message4': 'ainsi que la Politique des données de DADUPA.',
    signUpWith : 'Ou me connecter avec:',
    'signup.form.Member': 'Déjà inscrit ? M\'identifier !',
    'signup.form.alreadyMember': 'Déjà inscrit ? M\'identifier !',
    signin: 'S\'inscrire',
    q_register: "Vous n\'avez pas de compte ? Inscription",
    firstName: 'Prénom',
    lastName: 'Nom',
    username: 'Identifiant',
    'signup.form.profilType': 'Vous-êtes ?',
    projectHolder: 'Startups',
    donor: 'VCs',
    accompanyingPerson: 'Hubs',
    phone: 'Numéro de téléphone',
    country: 'Pays',
    'filter.country.other': 'AUTRE : (Veuillez préciser)',
    city: 'Ville',
    step: 'Étape',
    'signup.review.step1': 'Étape 1',
    'signup.review.step2': 'Étape 2',
    edit: 'Modifier',
    'signup.review.profileType': 'Vous-êtes ?',
    signup: 'S\'inscrire',
    'footer.copyright': '2023 Tous droits réservés. DADUPA Consulting SARL',
    'footer.menu.about': 'À propos',
    'footer.menu.user_agreement': "Conditions Générales d\'Utilisation",
    'footer.menu.privacy': "Politique d\'Utilisation des Données ",
    'footer.menu.help': "Aide & FAQ",
    'footer.menu.media': "Presse & Médias",
    'footer.menu.jobs': "Stages & Carrières",
    'footer.menu.Services': "Services dadupa",
    'footer.menu.community': "Nos Réseaux Sociaux",
    'header.menu.project' : 'Startups',
    'header.menu.finance' : 'VCs',
    'header.menu.mentors' : 'Hubs',
    see_profile: 'Voir mon profil',
    my_offre: 'Mes offres',
    my_favorite: 'Mes favoris',
    favorite_page:'Mes favoris',
    setting: 'Paramètres',
    logout: 'Déconnexion',
    coverEdit:'Modifier la couverture',    
    searching_funder:'Levée de fonds',
    searching_mentor:'Besoin de mentorat',
    bio:'Bio',
    industry:'Secteur d\'activité',
    address:'Lieu',
    writesmthng: 'Exprimez-vos idées...',	
    image: 'Images',
    video: 'Vidéo',
    youtube: 'YouTube',
    'filter.typePost.public': 'Tout le monde',
    'filter.typePost.connexionsOnly': 'Réseau uniquement',
    'filter.typePost.teamOnly': 'Equipe uniquement',
    'filter.typePost.meOnly': 'Moi uniquement',
    publish: 'Publier',
    subscriptionKickoff: 'A rejoint DADUPA :',
    hoursingle:'il y a heure',
    hourplural:'il y a heures',
    daysingle :'il y a jour',
    dayplural :'il y a jours',
    weeksingle :'il y a semaine',
    weekplural :'il y a semaines',
    monthsingle : 'il y a mois',
    monthplural : 'il y a mois',
    seeAll: 'VOIR PLUS',
    tips:'Conseils de DADUPA',
    viewsingle:'vue',
    viewplural:'vues',
    skills: 'Compétences',
    'skills.form.new': 'Compétence',
    add: 'Ajouter',
    training: 'Formation',
    start: 'Date de début',
    end: 'Date de fin',
    ongoing: 'Présent',
    school: 'Etablissement',
    trainingDiploma: 'Diplôme',
    trainingSpecialty: 'Domaine d\'études',
    'filter.trainingSpecialty.business': 'Administration et gestion des affaires',
    'filter.trainingSpecialty.agriculture': 'Agriculture',
    'filter.trainingSpecialty.arts': 'Arts',
    'filter.trainingSpecialty.architecture': 'Architecture',
    'filter.trainingSpecialty.internationalBusiness': 'Affaires internationales',
    'filter.trainingSpecialty.publicAdministration': 'Administration publique',
    'filter.trainingSpecialty.biochemistry': 'Biochimie',
    'filter.trainingSpecialty.biotechnology': 'Biotechnologie',
    'filter.trainingSpecialty.banking': 'Banques',
    'filter.trainingSpecialty.biology': 'Biologie',
    'filter.trainingSpecialty.accounting': 'Comptabilité et finance',
    'filter.trainingSpecialty.chemistry': 'Chimie',
    'filter.trainingSpecialty.internationalTrade': 'Commerce international',
    'filter.trainingSpecialty.communication': 'Communication',
    'filter.trainingSpecialty.trade': 'Commerce',
    'filter.trainingSpecialty.law': 'Droit',
    'filter.trainingSpecialty.design': 'Design graphique',
    'filter.trainingSpecialty.economy': 'Economie',
    'filter.trainingSpecialty.energy': 'Energie',
    'filter.trainingSpecialty.finance': 'Finance',
    'filter.trainingSpecialty.management': 'Gestion',
    'filter.trainingSpecialty.construction': 'Génie civile',
    'filter.trainingSpecialty.industrialEngineering': 'Génie industriel',
    'filter.trainingSpecialty.electricalEngineering': 'Génie électrique',
    'filter.trainingSpecialty.mecanicalEngineering': 'Génie mécanique',
    'filter.trainingSpecialty.computerScience': 'Informatique',
    'filter.trainingSpecialty.marketing': 'Marketing',
    'filter.trainingSpecialty.maths': 'Mathématique',
    'filter.trainingSpecialty.projectManagement': 'Management de projet',
    'filter.trainingSpecialty.neuroscience': 'Neuroscience',
    'filter.trainingSpecialty.physics': 'Physique',
    'filter.trainingSpecialty.advertisement': 'Publicité',
    'filter.trainingSpecialty.hr': 'Ressources humaines',
    'filter.trainingSpecialty.health': 'Santé',
    'filter.trainingSpecialty.tourism': 'Tourisme, hôtellerie et voyages',
    'filter.trainingSpecialty.other': 'AUTRE : (Veuillez préciser)',
    description: 'Description',
    experience: 'Expériences',
    company: 'Entreprise',
    job: 'Poste',
    'filter.job.actuaire': 'Actuaire',
    'filter.job.admin': 'Administrateur',
    'filter.job.commercial': 'Agent Commercial',
    'filter.job.immobilier': 'Agent Immobilier',
    'filter.job.analystecomm': 'Analyste Commercial',
    'filter.job.analyst': 'Analyste',
    'filter.job.architect': 'Architecte',
    'filter.job.architectsi': 'Architecte de Système d\'Information',
    'filter.job.artist': 'Artiste',
    'filter.job.animart': 'Animateur artistique',
    'filter.job.assistant': 'Assistant',
    'filter.job.partner': 'Associé',
    'filter.job.managingPartner': 'Associé gérant',
    'filter.job.seniorPartner': 'Associé principal',
    'filter.job.insurer': 'Assureur',
    'filter.job.audit': 'Auditeur Financier',
    'filter.job.lawyer': 'Avocat',
    'filter.job.banker': 'Banquier',
    'filter.job.blogger': 'Blogueur',
    'filter.job.cameraman': 'Caméraman',
    'filter.job.cartographe': 'Cartographe',
    'filter.job.ceramist': 'Céramiste',
    'filter.job.expertcompt': 'Expert-comptable',
    'filter.job.volunteer': 'Bénévole',
    'filter.job.biotech': 'Bio-Informaticien',
    'filter.job.biologist': 'Biologiste',
    'filter.job.seniorExécutive': 'Cadre dirigeant',
    'filter.job.officeManager': 'Chef de bureau',
    'filter.job.teamLeader': 'Chef d\'équipe',
    'filter.job.chercheuragronomie': 'Chercheur en Agronomie',
    'filter.job.chercheurbio': 'Chercheur en Biologie, Médecine et Santé',
    'filter.job.chercheurchimie': 'Chercheur en Chimie',
    'filter.job.chercheurdroit': 'Chercheur en Droit',
    'filter.job.chercheurecologie': 'Chercheur en Ecologie',
    'filter.job.chercheureconomie': 'Chercheur en Economie',
    'filter.job.chercheurmath': 'Chercheur en Mathématiques',
    'filter.job.chercheurphysics': 'Chercheur en Physique',
    'filter.job.chercheurespace': 'Chercheur en Sciences de la Terre, de l\'Univers et de l\'Espace',
    'filter.job.chercheurtic': 'Chercheur en  Sciences et Technologie de l\'information et de la communication',
    'filter.job.chercheurhumanities': 'Chercheur en Sciences Humaines et Humanités',
    'filter.job.chimiste': 'Chimiste',
    'filter.job.coach': 'Coach',
    'filter.job.cofounder': 'Cofondateur',
    'filter.job.commissairecomptes': 'Commissaire aux Comptes',
    'filter.job.comptable': 'Comptable',
    'filter.job.consultant': 'Consultant',
    'filter.job.controlgestion': 'Contrôleur de Gestion',
    'filter.job.coordinator': 'Coordinateur',
    'filter.job.designer': 'Designer',
    'filter.job.designeranim': 'Designer d\'Animation',
    'filter.job.designerimage': 'Designer d\'Images Multidimensions',
    'filter.job.designerimageinteractif': 'Designer d\'Oeuvres Artistiques Interactives',
    'filter.job.designergraphiste': 'Designer Graphiste',
    'filter.job.dessinateurindus': 'Dessinateur Industriel',
    'filter.job.developpeur': 'Développeur',
    'filter.job.director': 'Directeur',
    'filter.job.ceo': 'Directeur général',
    'filter.job.coo': 'Directeur des opérations',
    'filter.job.cmo': 'Directeur marketing',
    'filter.job.cto': 'Directeur technique',
    'filter.job.hrd': 'Directeur des ressources humaines',
    'filter.job.cis': 'Directeur système d\'informations',
    'filter.job.communicationsDirector': 'Directeur communication',
    'filter.job.partnershipManager': 'Directeur partenariat',
    'filter.job.writer': 'Ecrivain',
    'filter.job.electricien': 'Electricien',
    'filter.job.electronicien': 'Electronicien',
    'filter.job.entrepreneur': 'Entrepreneur',    
    'filter.job.farmer': 'Exploitant Agricole',
    'filter.job.fiscaliste': 'Fiscaliste',
    'filter.job.founder': 'Fondateur',
    'filter.job.freelance': 'Freelance',
    'filter.job.geographe': 'Géographe',
    'filter.job.geologist': 'Géologue',
    'filter.job.graphist': 'Graphiste',
    'filter.job.history': 'Historien',
    'filter.job.hostel': 'Hôtelier',
    'filter.job.hydrobiology': 'Hydrobiologiste',
    'filter.job.inforgraphist': 'Infographiste 2D - 3D',
    'filter.job.ingaeronautique': 'Ingénieur Aéronautique',
    'filter.job.ingaerospatial': 'Ingénieur Aérospatial',
    'filter.job.ingagronome': 'Ingénieur Agronome',
    'filter.job.ingchemist': 'Ingénieur Chimiste',
    'filter.job.ingmanufacturing': 'Ingénieur de Fabrication',
    'filter.job.ingelectricien': 'Ingénieur Electricien',
    'filter.job.ingenergie': 'Ingénieur en Energie',
    'filter.job.ingrobotique': 'Ingénieur en Robotique',
    'filter.job.ingenvironment': 'Ingénieur Environnemental',
    'filter.job.inglogiciel': 'Ingénieur Logiciels',
    'filter.job.ingmecanique': 'Ingénieur Mécanique',
    'filter.job.ingmicrosystem': 'Ingénieur Microsystème',
    'filter.job.ingnanosystem': 'Ingénieur Nanosystème',
    'filter.job.ingnucleaire': 'Ingénieur Nucléaire',
    'filter.job.ingprojet': 'Ingénieur Projets',
    'filter.job.ingquality': 'Ingénieur Qualité',
    'filter.job.ingsystem': 'Ingénieur Systèmes',
    'filter.job.ingindustriel': 'Ingénieur industriel',
    'filter.job.investor': 'Investisseur',
    'filter.job.jurist': 'Juriste',
    'filter.job.laborantin': 'Laborantin',
    'filter.job.Logistician': 'Logisticien',
    'filter.job.manager': 'Manager',
    'filter.job.machinist': 'Machiniste',
    'filter.job.exportImport': 'Marchand Exportateur/Importateur',
    'filter.job.doctor': 'Médecin',
    'filter.job.mentor': 'Mentor',
    'filter.job.microbiologiste': 'Microbiologiste',
    'filter.job.notaire': 'Notaire',
    'filter.job.nutrition': 'Nutritionniste',
    'filter.job.pharmacist': 'Pharmacien',
    'filter.job.photographer': 'Photographe',
    'filter.job.physician': 'Physicien',
    'filter.job.chairman': 'Président Directeur Général',
    'filter.job.president': 'Président',
    'filter.job.dg': 'Directeur Général',
    'filter.job.principal': 'Principal',
    'filter.job.copywriter': 'Rédacteur Web',
    'filter.job.projectmanager': 'Chef de Projets',
    'filter.job.programManager': 'Responsable de Programme', 
    'filter.job.marketingmanager': 'Responsable Marketing',
    'filter.job.portfolioManager': 'Responsable de portefeuille',
    'filter.job.complianceOffice': 'Responsable de la conformité',
    'filter.job.qualityControlManager': 'Responsable contrôle qualité',
    'filter.job.digitalMarketingManager': 'Responsable marketing digital',
    'filter.job.seoSpecialist': 'Responsable du référencement (SEO)',
    'filter.job.socialMediaManager': 'Responsable réseaux sociaux',
    'filter.job.paidAdvertisingSpecialist': 'Responsable publicité payante',
    'filter.job.sociologue': 'Sociologue',
    'filter.job.statistics': 'Statisticien',
    'filter.job.intern': 'Stagiaire',
    'filter.job.technician': 'Technicien',
    'filter.job.traducteur': 'Traducteur',
    'filter.job.independent': 'Travailleur indépendant',
    'filter.job.vicePresident': 'Vice président',
    'filter.job.webmaster': 'Webmaster',    
    'filter.job.analystefinance': 'Analyste financier',
    'filter.job.journalism': 'Journaliste',
    'filter.job.analystegestion': 'Analyste de gestion',
    'filter.job.sound': 'Arrangeur musical',
    'filter.job.specialeffects': 'Technicien des effets spéciaux',
    'filter.job.content': 'Rédacteur web',
    'filter.job.other': 'AUTRE : (Veuillez préciser)',
    industry: 'Secteur d\'activité',
    'filter.secteur.agroalimentaire': 'Agroalimentaire',
    'filter.secteur.architecture': 'Architecture',
    'filter.secteur.art': 'Art',
    'filter.secteur.automotive': 'Automobile',
    'filter.secteur.aérospace': 'Aérospatial',
    'filter.secteur.big_data': 'Big data',
    'filter.secteur.bio': 'Bio technologie',
    'filter.secteur.btp': 'BTP',
    'filter.secteur.b2bSaas': 'B2B SaaS',
    'filter.secteur.commerce': 'Commerce',
    'filter.secteur.internationalCommerce': 'Commerce international',
    'filter.secteur.communication': 'Communication',
    'filter.secteur.climate': 'Climat',
    'filter.secteur.crypto': 'Crypto/Blockchain',
    'filter.secteur.design': 'Design',
    'filter.secteur.divertissement': 'Divertissement',
    'filter.secteur.droit': 'Droit',
    'filter.secteur.eau': 'Eau',
    'filter.secteur.ecommerce': 'E-commerce',
    'filter.secteur.education': 'Education',
    'filter.secteur.electronics': 'Electronique',
    'filter.secteur.energie': 'Energie',
    'filter.secteur.environement': 'Environnement',
    'filter.secteur.fintech': 'Services financiers',
    'filter.secteur.waste': 'Gestion des déchets',
    'filter.secteur.immobilier': 'Immobilier',
    'filter.secteur.ia': 'Intelligence Artificielle',
    'filter.secteur.internet_objets': 'Internet des Objets',
    'filter.secteur.game': 'Gaming',
    'filter.secteur.logistic': 'Logistique',
    'filter.secteur.marketPlace': 'Market place',
    'filter.secteur.medias': 'Médias',
    'filter.secteur.mode': 'Mode',
    'filter.secteur.ads': 'Publicité',
    'filter.secteur.jobs': 'Ressources Humaines',
    'filter.secteur.robotique': 'Robotique',
    'filter.secteur.vr': 'Réalité virtuelle',
    'filter.secteur.sante': 'Santé et bien-être',
    'filter.secteur.service': 'Services',
    'filter.secteur.security': 'Sécurité',
    'filter.secteur.sport': 'Sport et fitness',
    'filter.secteur.retail': 'Vente au Détail',  
    'filter.secteur.information': 'Information',  
    'filter.secteur.technologie': 'Technologie',
    'filter.secteur.telecom': 'Télécommunication',
    'filter.secteur.textile': 'Textile',
    'filter.secteur.tourism': 'Tourisme, hôtellerie et voyages',
    'filter.secteur.transport': 'Transport',
    'filter.secteur.other': 'AUTRE : (Veuillez préciser)',
    accomplishments: 'Réalisations',
    offerings:'Offres',
    history:'Historique',
    réseaux:'DADUPERS',
    friends: 'Réseau',
    requests: 'Invitations',
    suggestions: 'Suggestions de DADUPERS',
    sortedBy: 'Classés par:',
    'filter.sortedBy.name1': 'Ajoutés récemment',
    'filter.sortedBy.name2': 'Prénom',
    'filter.sortedBy.name3': 'Nom',
    'filter.sortedBy.type1': 'Entrepreneur',
    'filter.sortedBy.type2': 'Investisseur',
    'filter.sortedBy.type3': 'Mentor',
    searchContact: 'Rechercher un DADUPER',
    'header.menu.new' : 'Nouvelle offre',
    'project.add.desc1': 'Présentez votre offre',
    'offer.new.message2': 'Renseignez les informations sur votre offre',
    'project.add.detail_offre': "Showcase info clés",
    'form.prject_name': "Nom du projet",
    projectState: "Etat du projet",
    'filter.etat_projet.idee': 'Idée',
    'filter.etat_projet.prototype': 'Prototype',
    'filter.etat_projet.mvp': 'MVP',
    'filter.etat_projet.first_sale': 'Croissance',
    fundingSearch: "Financement recherché",
    'filter.secteur.99': '$ 99,9K et moins',
    'filter.secteur.100-999': '$ 100K - $ 999,9K',
    'filter.secteur.1m': '$ 1M - et Plus',
    'form.add_logo': 'Insérez logo',
    'form.want_mentors': 'Je cherche des mentors',
    'funder.form.proposition': 'Je propose également du mentorat',
    'form.add_url': 'Site web',
    url: 'URL',
    'upload_video': 'Pitch (Vidéo)',
    uploadVideoMessage1: 'Sélectionner les fichiers multimédias à importer',
    uploadVideoMessage2: 'ou glisser-déposer',
    uploadVideoBouton: 'Importer',
    uploadlinkMessage1: 'Importer des vidéos de YouTube ou Vimeo',
    uploadlinkMessage2: 'Copiez / collez le lien de votre vidéo ici',
    uploadlinkBouton: 'Importer',
    'form.uploadlink': 'Coller le lien ici',
    previewlinkBouton: 'Aperçu',
    desc_offre: 'Description de Projet',
    addTags: 'Ajouter des tags (Appuyer sur ENTRÉE pour ajouter)',
    tags: 'Tags',
    'form.edit_logo': "Modifer le logo",
    'review_detail': "Vérification",
    'funder.add.desc1': 'Présentez votre offre de financement',
    'funder.form.you_are': "Vous êtes:",
    'funder.form.you_are.business_angle' : 'Business Angel',
    'funder.form.you_are.fonds' : 'VC',
    'funder.form.you_are.corporate' : 'Corporate',    
    'funder.form.financement': "Capacité de financement",
    'funder.form.date': "Date limite",
    'header.menu.mentoring' : 'Mentoring',
    'form.new.mentoringType': "Modalité",
    'filter.new.mentoringType.free' : 'Gratuit',
    'filter.new.mentoringType.paid' : 'Payant',
    'form.new.mentorType': "Vous êtes :",
    'mentor.form.individual' : 'Particulier',
    'mentor.form.incubator' : 'Incubateur',
    'mentor.form.accelerator' : 'Accélérateur',
    'form.add.coEntrepreneur': "Ajouter des membres de votre équipe",
    'form.add.coInvestor': "Ajouter des Co-investisseurs",
    'form.add.coMentor': "Ajouter des Co-mentors",
    newComment: 'Ecrivez un commentaire',
    shareplural : 'Partages',
    sharesingle:'Partage',
    partagez:"Partagez",
    commentplural:'commentaires',
    comment:'Commentez',
    commentsingle:'commentaire',    
    clap: 'Bravo',
    details: 'Détails',
    documents: 'Documents',
    noContentAvailable: 'Aucun contenu disponible',
    favoriteConfirmationMessage1: 'Confirmer l\'ajout aux favoris',
    favoriteConfirmationMessage2: 'Êtes-vous sûr de vouloir ajouter ce contenu à vos favoris ?',
    favoriteDeleteMessage1: 'Confirmer le retrait des favoris',
    favoriteDeleteMessage2: 'Êtes-vous sûr de vouloir retirer ce contenu de vos favoris ?',
    no: 'Non',
    yes: 'Oui',
    notifications : 'Notifications',
    clapnotification:' vous dit Bravo',
    commentnotification:'a commenté votre publication',
    sharingnotification:'a partagé votre publication',
    requestnotification:'souhaite faire partie de votre réseau',
    cofoundernotification:'vous invite comme co-porteur',
    cofounderconfirmation:'fait partie des co-porteurs de votre offre',
    coinvestorNotification:'vous invite comme Co-investisseur',
    coinvestorConfirmation:'fait partie des Co-investisseurs de votre offre',
    comentorNotification:'vous invite comme Co-mentor',
    comentorConfirmation:'fait partie des Co-mentors de votre offre',
    unread: 'Marqué comme non lu',
    mask:'Masquez',
    seeMore:'Voir plus',
    generalsettings:'Paramètres généraux',
    general: 'Général',
    account:'Mon compte',
    accountUpdate: 'Mise à jour du compte',
    settingsettup:'Configurez votre compte',
    access:'Mes accès',
    access_details:'Mes informations d\'accès',
    forget_password:'Mot de passe oublié ?',
    passwordUpdate: 'Mise à jour du mot de passe',
    'form.presentPassword': 'Mot de passe actual',
    'form.newPassword': 'Nouveau mot de passe',
    emailConfirmation: 'Renvoyer l\'e-mail de confirmation',
    settingconfirm: 'Confirmer',
    interface:'Interface',
    update: 'Actualiser',
    refresh:'Actualiser',
    remember_me: 'Se souvenir de moi',
    invitationsToConsent: 'Invitations à accepter',
    sentInvitations: 'Invitations envoyées',
    receivedInvitations: 'Invitations reçues',
    askAccess: 'Accès demandé',
    to: 'à',
    from: 'de',
    categorie: 'Catégorie',
    link: 'Lien',
    role: 'Rôle',
    date: 'Date',
    action: 'Action',
    'header.menu.articles' : 'Opportunités',
    'form.menu.articles.title': 'Titre',
    'form.menu.articles.category': 'Catégorie',
    'filter.articles.category.calls4projects': 'Appels à projets',
    'filter.articles.category.fundraising': 'Levées de fonds',
    'filter.articles.category.news': 'Actualité',
    suggestedArticles: 'Articles recommandés',
    topArticles: 'Top articles',
    already_a_member:'Déjà inscrit ?',
    or_sign_up_with:'Ou se connecter avec',
    login: 'Connexion',
    'error.credentials' : "Ces références ne correspondent pas à nos données.",
    'messages' : 'Messages',
    'filter.search': 'Mots clés',
    or_copy_link : 'Ou copier le lien',
    'choose_file': 'Choisir un fichier',
    'filter.fund.type': 'Type de fonds',
    'filter.fundtype.preseed':'Pre-seed',
    'filter.fundtype.seed':'Seed',
    'filter.fundtype.venturecap':'Venture Capital',
    'has_deadline': 'Aucun délai',
    'postuler': 'Candidatez',
    'target_zone': 'Zone cible',
    'target_sector': 'Secteur d\'activité ciblé',
    'published_at': 'Publié le',
    'write_comment': 'Ecrivez votre commentaire',
    writeMessage: 'Ecrivez un message',
    onligne: 'OEn ligne',
    newMessage: 'Nouveau message',
    seeallmsgs:'Voir tous les messages',
    multimedia:'Images/Vidéos',
    sponsored:'Sponsorisé',
    filter_selection: 'selection',
    'no_notifications': 'Aucune notification disponible',
    'no_access_to_content_alert': 'Vous ne disposez pas des autorisations nécessaires pour accéder à ce contenu. Veuillez contacter le propriétaire afin d\'obtenir l\'accès.',
    'no_access_to_content_popup': 'Veuillez contacter le propriétaire afin d\'obtenir l\'accès.',
    'zone':'Zone',
    'owners': 'Vue d\'ensemble',
    noNotifAvailable: 'Aucune notification disponible',
    report:'Signaler',
    'report_select_problem':'Veuillez indiquer votre préoccupation',
    'report_select_problem_message':'Parce que nous attachons de l\'importance à votre sécurité et à l\'intégrité de la communauté, votre vigilance est essentielle au maintien d\'un environnement respectueux et sûr pour tous les utilisateurs.',
    'report_level':'Indiquer le degré de gravité',
    'report_low' : 'Faible',
    'report_medium' : 'Moyen', 
    'report_high' : 'Élevé',
    'report_select_idea' : 'Plagiat',
    'report_select_nudity' : 'Contenu inapproprié',
    'report_select_violence' : 'Harcèlement',
    'report_select_spam' : 'Spam',
    'report_select_speech' :'Discours haineux',
    'report_select_information' :'Information trompeuse',
    'report_select_bots' : 'Faux compte',
    'report_select_funds' : 'Malversation des fonds',
    send:'Signaler',
    unclap: 'Décocher',
    reply:'Répondre',
    'select_country':'Choisir un pays',
    'send_invite':'Inviter',
    searching_funder_descrip:'Ce compte souhaite entrer en contact avec des investisseurs.',
    searching_mentor_descrip:'Ce compte souhaite entrer en contact avec des mentors.',
    articles: 'Opportunités',
    search: 'Rechercher',
    all: 'Tous',
    provide_mentoring_descrip:'Ce compte offre également du mentoring.',
    provide_mentoring:'Financement & Mentoring',
    delete:'Supprimer',
    cancel:'Annuler',
    edit_comment:'Modifier le commentaire',
    favorite:'Favoris',
    name_of_skill:'Nom de la compétence',    
    please_confirm_your_email_address:'Veuillez confirmer votre adresse e-mail',
    please_activate_your:'Merci d\'activer votre',
    hi:'Salut',
    in_order_to_start_using_your_account_you_need_to_confirm_your_email:'Afin de commencer à utiliser votre compte, vous devez confirmer votre adresse email',
    objet_welcome_mail:'Bienvenue sur DADUPA :',
    welcome_ligne1:'Hello!',
    welcome_ligne2:'Bienvenue sur DADUPA, le réseau social des entrepreneurs.',
    welcome_ligne3:'Sans plus attendre, libérez votre potentiel avec cette communauté unique de porteurs de projets, d\'investisseurs et de mentors.',
    welcome_ligne4:'Retour sur DADUPA',
    welcome_ligne5:'Si vous ne vous êtes pas inscrit à ce compte, vous pouvez ignorer cet e-mail et le compte sera supprimé automatiquement après 30 jours.',
    welcome_ligne6:'Pour plus d\'informations, n\'hésitez pas à consulter le Centre d\'aide ou à nous contacter.',
    welcome_ligne7:'L\'équipe DADUPA',
    objet_msg_notif : 'vous a écrit',
    msg_notif_ligne1: 'Hello!', 
    msg_notif_ligne2: 'Vous avez un nouveau message.',
    msg_notif_ligne3: 'Voir le message',
    msg_notif_ligne4: 'Pour plus d\'informations, n\'hésitez pas à consulter le Centre d\'aide ou à nous contacter.',
    msg_notif_ligne5: 'L\'équipe DADUPA',
    objet_request_notif: 'vous a envoyé une demande de connexion',
    request_ligne1: 'Hello!',
    request_ligne2: 'souhaite faire partie de vos connexions sur DADUPA.',
    request_ligne3_left: 'Voir le profil',
    request_ligne3_right: 'Accepter',
    request_ligne4: 'Pour plus d\'informations, n\'hésitez pas à consulter le Centre d\'aide ou à nous contacter.',
    request_ligne5: 'L\'équipe DADUPA',
    reset_password: 'Réinitialiser le mot de passe',
    you_are_receiving_this_email_because_we_received_a_password_reset_request_for_your_account: 'Vous recevez cet e-mail parce que nous avons reçu une demande de réinitialisation de mot de passe pour votre compte.',
    reset_your_password: 'Réinitialisez votre mot de passe',
    if_you_did_not_request_a_password_reset_no_further_action_is_required: 'Si vous n\'avez pas demandé une réinitialisation de mot de passe, aucune autre action n\'est requise.',
    contact_support_team: 'Contacter l\'équipe de support',    
    you_have_successfully_reset_your_password: 'Vous avez réinitialisé avec succès votre mot de passe.',
    this_is_a_confirmation_email_that_your_password_for_your_account: 'Il s\'agit d\'un e-mail de confirmation que le mot de passe de votre compte vient d\'être changé.',    
    if_this_is_your_account_but_you_didnt_request_a_password_change_you_can_reset_your_password_here_if_you_re_having_trouble: 'Si vous constatez une modification de votre mot de passe sans avoir initié ce changement, vous avez la possibilité de réinitialiser votre mot de passe ici. En cas de difficultés ou de problèmes, n\'hésitez pas à nous contacter',
    objet_comment_notif: 'a commenté votre projet',
    comment_notif_ligne1: 'Hello!', 
    comment_notif_ligne2: 'Voir le commentaire',
    comment_notif_ligne3: 'Pour plus d\'informations, n\'hésitez pas à consulter le Centre d\'aide ou à nous contacter.',
    comment_notif_ligne4: 'L\'équipe DADUPA',
    objet_article_notif: 'has commented on your article',
    article_notif_ligne1: 'Hello!', 
    article_notif_ligne2: 'See the comment',
    article_notif_ligne3: 'Pour plus d\'informations, n\'hésitez pas à consulter le Centre d\'aide ou à nous contacter.',
    article_notif_ligne4: 'L\'équipe DADUPA',
    objet_post_notif: 'a commenté votre post',
    post_notif_ligne1: 'Hello!', 
    post_notif_ligne2: 'Voir le commentaire',
    post_notif_ligne3: 'Pour plus d\'informations, n\'hésitez pas à consulter le Centre d\'aide ou à nous contacter.',
    post_notif_ligne4: 'L\'équipe DADUPA',
    email_not_confirmed: 'Votre e-mail n\'est pas confirmé',
    french: 'Français',
    english: 'Anglais',
    pictures: 'Photos',
    visit_website: 'Visiter le site web',
    'footer.menu.cookie': "Aide & FAQ",
    'footer.menu.copyright': '2022 Tous droits réservés. Dadupa Connect',
    'footer.menu.brand': "Stages & Carrières",
    'footer.menu.guest': "Services dadupa",
    'form.profilsearchby': 'Rechercher par',
    select_a_country : 'Sélectionnez un pays',
    auth_response_error : 'E-mail ou mot de passe incorrect !',
    'password.8caracteresminimum' : 'Mot de passe : 8 caractères minimum',
    request_sent_accept : 'Demande de connexion envoyée',
    'form.want_funder' : 'Je cherche des investisseurs',
    'Select': 'Sélectionner',

    afghanistan:'Afghanistan',
    åland_Islands:'Îles Åland',
    albania:'Albanie',
    algeria:'Algérie',
    american_samoa: 'Samoa américaines',
    andorra: 'Andorre',
    angola: 'Angola',
    anguilla: 'Anguilla',
    antarctica: 'Antarctique',
    antigua_and_barbuda: 'Antigua-et-Barbuda',
    argentina: 'Argentine',
    armenia: 'Arménie',
    aruba: 'Aruba',
    australia: 'Australie',
    austria: 'Autriche',
    azerbaijan: 'Azerbaïdjan',
    bahamas:'Bahamas',
    bahrain:'Bahreïn',
    bangladesh: 'Bangladesh',
    barbados: 'Barbade',
    belarus: 'Biélorussie',
    belgium: 'Belgique',
    belize: 'Belize',
    benin: 'Bénin',
    bermuda: 'Bermudes',
    bhutan: 'Bhoutan',
    bolivia: 'Bolivie',
    bonaire: 'Bonaire',
    bosnia: 'Bosnie',
    botswana: 'Botswana',
    bouvet_Island: 'Île Bouvet',
    brazil: 'Brésil',
    british_Indian_Ocean_Territory: 'Territoire britannique de l\’océan Indien',
    brunei_Darussalam: 'Brunei Darussalam',
    bulgaria: 'Bulgarie',
    burkina_Faso: 'Burkina Faso',
    burundi: 'Burundi',
    cabo_Verde: 'Cap-Vert',
    cambodia: 'Cambodge',
    cameroon: 'Cameroun',
    canada: 'Canada',
    cayman_Islands: 'Îles Caïmans',
    central_African_Republic: 'République centrafricaine',
    chad: 'Tchad',
    chile: 'Chili',
    china: 'Chine',
    christmas_Island: 'Île Christmas',
    colombia: 'Colombie',
    comoros: 'Comores',
    congo: 'Congo',
    congo_Democratic_Republic_of_the: 'Congo, République démocratique',
    cook_Islands: 'Îles Cook',
    costa_Rica: 'Costa Rica',
    croatia: 'Croatie',
    cuba: 'Cuba',
    curaçao: 'Curaçao',
    cyprus: 'Chypre',
    czechia: 'Tchéquie',
    côte_dIvoire: 'Côte d\'Ivoire',
    denmark: 'Danemark',
    djibouti: 'Djibouti',
    dominica: 'Dominique',
    dominican_Republic: 'République dominicaine',
    ecuador: 'Équateur',
    egypt: 'Égypte',
    el_Salvador: 'Salvador',
    equatorial_Guinea: 'Guinée équatoriale',
    eritrea: 'Érythrée', 
    estonia: 'Estonie',
    eswatini: 'Eswatini',
    ethiopia: 'Éthiopie',
    falkland_Islands_Malvinas: 'Îles Falkland (Malvinas)',
    faroe_Islands: 'Îles Féroé',
    fiji: 'Fidji',
    finland: 'Finlande',
    france: 'France',
    french_Guiana: 'Guyane française',
    french_Polynesia: 'Polynésie française',
    french_Southern_Territories: 'Terres australes françaises',
    gabon: 'Gabon',
    gambia: 'Gambie',
    georgia: 'Géorgie',
    germany: 'Allemagne',
    ghana: 'Ghana',
    gibraltar: 'Gibraltar',
    greece: 'Grèce',
    greenland: 'Groenland',
    grenada: 'Grenade',
    guadeloupe: 'Guadeloupe',
    guam: 'Guam',
    guatemala: 'Guatemala',
    guernsey: 'Guernesey',
    guinea: 'Guinée',
    guinea_Bissau: 'Guinée-Bissau',
    guyana: 'Guyana',
    haiti: 'Haïti',
    heard_Island_and_McDonald_Islands: 'Île Heard et îles McDonald',
    holy_See: 'Saint-Siège',
    honduras: 'Honduras',
    hong_Kong: 'Hong Kong',
    hungary: 'Hongrie',
    iceland: 'Islande',
    india: 'Inde',
    indonesia: 'Indonésie',
    iran_Islamic_Republicof: 'Iran, République islamique',
    iraq: 'Irak',
    isle_of_Man: 'Île de Man',
    italy: 'Italie',
    jamaica: 'Jamaïque',
    japan: 'Japon',
    jersey: 'Jersey',
    jordan: 'Jordanie',
    kazakhstan: 'Kazakhstan',
    kenya: 'Kenya',
    kiribati: 'Kiribati',
    korea_Democratic_Peoples_Republic_of: 'Corée, République populaire démocratique',
    korea_Republic_of: 'Corée, République',
    kuwait: 'Koweït',
    kyrgyzstan: 'Kirghizistan',
    lao_Peoples_Democratic_Republic: 'République démocratique populaire lao',
    latvia: 'Lettonie',
    lebanon: 'Liban',
    lesotho: 'Lesotho',
    liberia: 'Libéria',
    libya: 'Libye',
    liechtenstein: 'Liechtenstein',
    lithuania: 'Lituanie',
    luxembourg: 'Luxembourg',
    macao: 'Macao',
    madagascar: 'Madagascar',
    malawi: 'Malawi',
    malaysia: 'Malaisie',
    maldives: 'Maldives',
    mali: 'Mali',
    malta: 'Malte',
    marshall_Islands: 'Îles Marshall',
    martinique: 'Martinique',
    mauritania: 'Mauritanie',
    mauritius: 'Maurice',
    mayotte: 'Mayotte',
    mexico: 'Mexique',
    micronesia_Federated_States_of: 'Micronésie, États fédérés',
    moldova_Republic_of: 'Moldavie, République',
    monaco: 'Monaco',
    mongolia: 'Mongolie',
    montenegro: 'Monténégro',
    montserrat: 'Montserrat',
    morocco: 'Maroc',
    mozambique: 'Mozambique',
    myanmar: 'Myanmar',
    namibia: 'Namibie',
    nauru: 'Nauru',
    nepal: 'Népal',
    netherlands: 'Pays-Bas',
    new_Caledonia: 'Nouvelle-Calédonie',
    new_Zealand: 'Nouvelle-Zélande',
    nicaragua: 'Nicaragua',
    niger: 'Niger',
    nigeria: 'Nigeria',
    niue: 'Niue',
    norfolk_Island: 'Île Norfolk',
    north_Macedonia: 'Macédoine du Nord',
    northern_Mariana_Islands: 'Îles Mariannes du Nord',
    norway: 'Norvège',
    oman: 'Oman',
    pakistan: 'Pakistan',
    palau: 'Palaos',
    palestine_State_of: 'Palestine, État',
    panama: 'Panama',
    papua_New_Guinea: 'Papouasie-Nouvelle-Guinée',
    paraguay: 'Paraguay',
    peru: 'Pérou',
    philippines: 'Philippines',
    pitcairn: 'Pitcairn',
    poland: 'Pologne',
    portugal: 'Portugal',
    puerto_Rico: 'Porto Rico',
    qatar: 'Qatar',
    romania: 'Roumanie',
    russian_Federation: 'Fédération de Russie',
    rwanda: 'Rwanda',
    réunion: 'Réunion',
    saint_Barthélemy: 'Saint-Barthélemy',
    saint_Helena_Ascension_and_Tristan_da_Cunha: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    saint_Kitts_and_Nevis: 'Saint-Kitts-et-Nevis',
    saint_Lucia: 'Sainte-Lucie',
    saint_Martin: 'Saint-Martin (partie française)',
    saint_Pierre_and_Miquelon: 'Saint-Pierre-et-Miquelon',
    saint_Vincent_and_the_Grenadines: 'Saint-Vincent-et-les-Grenadines',
    samoa: 'Samoa',
    san_Marino: 'Saint-Marin',
    sao_Tome_and_Principe: 'Sao Tomé-et-Principe',
    saudi_Arabia: 'Arabie saoudite',
    senegal: 'Sénégal',
    serbia: 'Serbie',
    seychelles: 'Seychelles',
    sierra_Leone: 'Sierra Leone',
    singapore: 'Singapour',
    sint_Maarten: 'Saint-Martin (partie néerlandaise)',
    slovakia: 'Slovaquie',
    slovenia: 'Slovénie',
    solomon_Islands: 'Îles Salomon',
    somalia: 'Somalie',
    south_Africa: 'Afrique du Sud',
    south_Georgia_and_the_South_Sandwich_Islands: 'Géorgie du Sud-et-les îles Sandwich du Sud',
    south_Sudan: 'Soudan du Sud',
    spain: 'Espagne',
    sri_Lanka: 'Sri Lanka',
    sudan: 'Soudan',
    suriname: 'Suriname',
    svalbard_and_Jan_Mayen: 'Svalbard et Jan Mayen',
    sweden: 'Suède',
    switzerland: 'Suisse',
    syrian_Arab_Republic: 'République arabe syrienne',
    taiwan_Province_of_China: 'Taïwan, Province de Chine',
    tajikistan: 'Tadjikistan',
    tanzania_United_Republic_of: 'Tanzanie, République unie',
    thailand: 'Thaïlande',
    timor_Leste: 'Timor-Leste',
    togo: 'Togo',
    tokelau: 'Tokelau',
    tonga: 'Tonga',
    trinidad_and_Tobago: 'Trinité-et-Tobago',
    tunisia: 'Tunisie',
    turkey: 'Turquie',
    turkmenistan: 'Turkménistan',
    turks_and_Caicos_Islands: 'Îles Turques-et-Caïques',
    tuvalu: 'Tuvalu',
    uganda: 'Ouganda',
    ukraine: 'Ukraine',
    united_Arab_Emirates: 'Émirats arabes unis',
    united_Kingdom: 'Royaume-Uni',
    united_States_Minor_Outlying_Islands: 'Îles mineures éloignées des États-Unis',
    united_States: 'États-Unis',
    uruguay: 'Uruguay',
    uzbekistan: 'Ouzbékistan',
    vanuatu: 'Vanuatu',
    venezuela_Bolivarian_Republic_of: 'Venezuela, République bolivarienne',
    vietNam: 'Viet Nam',
    virgin_Islands_British: 'Îles Vierges britanniques',
    virgin_Islands_US: 'Îles Vierges des États-Unis',
    wallis_and_Futuna: 'Wallis-et-Futuna',
    yemen: 'Yémen',
    zambia: 'Zambie',
    zimbabwe: 'Zimbabwe',
};

export default fr;