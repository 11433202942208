const sectors = [
    ['agri.business', 'AgriTech / AgriBusiness'],
    ['ai.blockchain.iot.robotics', 'AI, Blockchain, IoT, Robotics'],
    ['business.softwares', 'Business softwares'],
    ['climate.environmental', 'Climate & Environmental'],
    ['communication.data', 'Communication, Data...'],
    ['culture.creative', 'Culture & Creative'],
    ['ecommerce.retailtech', 'E-commerce & RetailTech'],
    ['edtech', 'EdTech'],
    ['energy.cleantech', 'Energy & CleanTech'],
    ['fintech', 'FinTech'],
    ['foodtech', 'FoodTech'],
    ['govtech', 'GovTech'],
    ['healthtech', 'HealthTech'],
    ['logistics.supply.chain', 'Logistics & Supply Chain'],
    ['mobility.transport', 'Mobility & Transport']
];

export default sectors;