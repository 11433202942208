
const etatsCreate = [
    // ["", "filter.etat_projet"],
    ['mvp', 'MVP'],
    ['pre.seed.seed', 'Pre-seed / Seed'],
    ['series.a.c', 'Series A - C'],
    ['series.d.more', 'Series D & more'],
    ['others', 'Others']

];


export default etatsCreate;