const sectors = [
   {value: 'agri.business', label: 'AgriTech / AgriBusiness'},
    {value: 'ai.blockchain.iot.robotics', label: 'AI, Blockchain, IoT, Robotics'},
    {value: 'business.softwares', label: 'Business softwares'},
    {value: 'climate.environmental', label: 'Climate & Environmental'},
    {value: 'communication.data', label: 'Communication, Data...'},
    {value: 'culture.creative', label: 'Culture & Creative'},
    {value: 'ecommerce.retailtech', label: 'E-commerce & RetailTech'},
    {value: 'edtech', label: 'EdTech'},
    {value: 'energy.cleantech', label: 'Energy & CleanTech'},
    {value: 'fintech', label: 'FinTech'},
    {value: 'foodtech', label: 'FoodTech'},
    {value: 'govtech', label: 'GovTech'},
    {value: 'healthtech', label: 'HealthTech'},
    {value: 'logistics.supply.chain', label: 'Logistics & Supply Chain'},
    {value: 'mobility.transport', label: 'Mobility & Transport'}

];
export default sectors;